<template>
  <div class="validation-information">
    <div class="validation-information--cartouche">
      <v-icon v-if="hasCedantValidated" class="mr-2 text-success" name="check-circle" />
      <v-icon v-else class="mr-2 text-danger" name="times-circle" />CEDANT
    </div>
    <div class="validation-information--cartouche mt-1">
      <v-icon v-if="hasRepreneurValidated" class="mr-2 text-success" name="check-circle" />
      <v-icon v-else class="mr-2 text-danger" name="times-circle" />REPRENEUR
    </div>
  </div>
</template>
<script>
export default {
  name: 'validationCartouche',
  props: {
    hasCedantValidated: {
      type: Boolean,
      default: false
    },
    hasRepreneurValidated: {
      type: Boolean,
      default: false
    },

  }
}
</script>
<style lang="scss">
@import '@/styles/_variables.scss';

.validation-information {
  position: fixed;
  top: 30%;
  right: 0;
  z-index: 2;
}
.validation-information--cartouche {
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  border-radius: 50px 0px 0px 50px;
  background-color: $white-lighten-1;
  width: 150px;
}
</style>
